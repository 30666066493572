import packageJson from '../../package.json'
import merge from 'lodash.merge'

import client from '../client'
import {getBrowserName, getUniqueDeviceId} from '../libs/foundation'
import voltApi from 'client-api'

const requestValidity = {
    INSTANT: 45 * 1000, // 45 secondes, when used to force almost an instant refresh
    DEFAULT: 15 * 60 * 1000, // 15 minutes
    MIN_40: 40 * 60 * 1000,
    HOUR_1: 60 * 60 * 1000,
    YOUTUBE: 3 * 60 * 60 * 1000, // To manage youtube quotas
}

export const LOCAL_STORAGE_KEYS_CHECKOUT = {
    CHECKOUT_DATA: 'checkoutData',
    BILLING_DETAIL_DATA: 'billingDetail',
}

interface dateTypesInterface {
    'MMM DD, YYYY': {}
    'dd/MM/YYYY, HH:mm A': {}
    'MMM DD, YYYY, HH:mm A': {}
}

export const dateTypes: dateTypesInterface = {
    'MMM DD, YYYY': {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    },
    'dd/MM/YYYY, HH:mm A': {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Ensures AM/PM format
    },
    'MMM DD, YYYY, HH:mm A': {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Ensures AM/PM format
    },
}

export const buttonNames = {
    ACTIVATE: 'ACTIVATE',
    CANCEL: 'CANCEL',
    PAUSE: 'PAUSE',
    REINSTATE: 'REINSTATE',
    CONTINUE: 'CONTINUE',
    CHANGE_PLAN: 'CHANGE_PLAN',
}

export const statusNames = {
    PENDING_PAUSE: 'PENDING_PAUSE',
    PENDING_CANCEL: 'PENDING_CANCEL',
    NOT_ACTIVATED: 'NOT_ACTIVATED',
    PAUSED: 'PAUSED',
}

export const CURRENCY_SYMBOL = {
    USD: '$',
    EUR: '€',
}

export const PRODUCT_FREQUENCY = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',
}

export const SUBSCRIPTION_STATUS = {
    PENDING_CANCEL: 'PENDING_CANCEL',
    PAUSED: 'PAUSED',
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    INACTIVE: 'INACTIVE',
    PENDING_PAUSE: 'PENDING_PAUSE',
    FREE_TRIAL: 'Free/Trial',
    PAYMENT_ERROR: 'PAYMENT_ERROR',
    INACTIVE_WITH_FREE_TRIAL: 'INACTIVE_WITH_FREE_TRIAL',
    WAITING_ACTIVATION_LINK: 'WAITING_ACTIVATION_LINK',
    CANCELLED: 'CANCELLED',
}

export const BILLING_DETAIL_STATUS = {
    BILLING_DETAIL_PENDING_PAUSE: 'Pending Pause',
    BILLING_DETAIL_PAUSED: 'Paused',
    BILLING_DETAIL_PENDING_CANCEL: 'Pending Cancel',
    BILLING_DETAIL_ACTIVE: 'Active',
    BILLING_DETAIL_EXPIRED: 'Expired',
    BILLING_STATE_GRACE_PERIOD: 'Grace Period',
    BILLING_STATE_IN_RETRY: 'In Retry',
    BILLING_STATE_UNUSABLE_PAYMENTMETHOD: 'Unusable PaymentMethod',
    BILLING_DETAIL_CANCELLED: 'Cancelled',
}

export const ZONE_TYPE = {
    HERO: 'hero',
    CONTENT_RAIL: 'content_rail',
    CONTENT_WALL: 'content_wall',
    SINGLE_CONTENT_BLOCK: 'single_content_block',
    MULTIPLE_CONTENT_BLOCKS: 'multiple_content_blocks',
}

export const TIER_STATUS = {
    NOT_QUALIFIED: 'not_qualified',
}
export const STORED_PRODUCT_ID = 'storedProductId'

export const ZONE_TITLE_LOGO_VISIBILITY_FLAG = {
    LOGO_AND_TEXT: '0',
    TEXT_ONLY: '1',
    LOGO_ONLY: '2',
    NONE: '3',
}

export const ALL_STATUS_TYPES = {
    ...SUBSCRIPTION_STATUS,
    ...BILLING_DETAIL_STATUS,
    ...TIER_STATUS,
    ACTIVATION_REQUIRED: 'ACTIVATION_REQUIRED',
    ACTIVATION_NOT_REQUIRED: 'ACTIVATION_NOT_REQUIRED',
}

export const ANALYTICS_EVENTS_TYPES = {
    ITEM_CLICK_HERO: 'item_click_hero',
    ITEM_CLICK_CONTENT_RAIL: 'item_click_content_rail',
    ITEM_CLICK_CONTENT_WALL: 'item_click_content_wall',
    ITEM_CLICK_HTML_ZONE: 'item_click_html_zone',
    ITEM_CLICK_SINGLE_CONTENT_BLOCK: 'item_click_single_content_block',
    ITEM_CLICK_MULTIPLE_CONTENT_BLOCK: 'item_click_multiple_content_block',
    ERROR: 'error',
    ADD_TO_CART: 'add_to_cart',
    BEGIN_CHECKOUT: 'begin_checkout',
    PURCHASE: 'Purchase',
    REMOVE_FROM_CART: 'remove_from_cart',
    REMOVE_FROM_CHECKOUT: 'remove_from_checkout',
    ACCESS_NOW: 'access_now',
}

export const CARE_AGENT_TABLE_COLUMNS = {
    USER_NAME: 'userName',
    USER_ID_LABEL: 'userIDLabel',
    LAST_ACTIVE: 'lastActive',
}

export const SORT_ORDER = {
    ASCENDING: 'asc',
    DESCENDING: 'desc',
}

export const HERO_BANNER_SIZE = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
}
export const HERO_BANNER_MEDIA_PLAYER_ALIGNMENT = {
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    ON: 'on',
    TO_LEFT: 'to left',
    TO_RIGHT: 'to right',
}

const config = {
    deviceData: {
        deviceType: 'COMMON',
        deviceNickName: 'COMMON',
        deviceName: 'COMMON',
        deviceOS: getBrowserName(voltApi),
    },
    showPlayerEventLog: false,
    version: packageJson.version,
    // Time diff between what we see from a live and the real moment it's broadcast
    liveDelay: 15,
    maxBitRate: 3000,
    targetOSVersionToLimitQuality: null,
    displayPlayerInfo: false,
    storage: {
        authToken: 'authToken',
        authTokenTS: 'authTokenTS',
        deviceSecureId: 'deviceSecureId',
        customerId: 'customerId',
        tempPcLevel: 'tempPcLevel',
        appLanguage: 'appLanguage',
        defaultAudio: 'defaultAudio',
        defaultSubtitles: 'defaultSubtitles',
    },

    errors: {
        logInRequired: 'logInRequired',
        loginFailed: 'loginFailed',
    },
    mirroringMap: {
        he: true,
        en: false,
    },

    infoBannerMiniEpg: {
        pastDays: 14,
        nextDays: 7,
    },
}

const baseConfig = {
    uniqueDeviceId: getUniqueDeviceId(),

    touchable: {
        hitSlopSize: 15,
    },

    defaultEmiPollingConfig: {
        periodicDelay: 30, // In minutes
    },

    reminders: {
        //in seconds, interval between two polling to retrieve reminders
        pollingInterval: 30 * 60, // 30 minutes
        // in seconds, delay used to compute when first reminder should be display for a program
        preventDelay: 30,
        // in seconds, delay used when user snooze the reminder
        // used to "simulate" snooze (x N times) if preventDelay is already in the past when reminder is retrieved
        snoozeDelay: 5 * 60, // 5 minutes
        // in seconds, delay used to remind user before the live starts when the last snooze was done
        // (last snooze = another snooze will lead to a reminder after start of the program)
        lastChanceDelay: 30,
    },

    defaultRequestLimit: {mobile: 15, tablet: 30}, // These values have to be synchronized with the values in theme: `row.numOfTiles.mobile` and `row.numOfTiles.tablet`
    liveNowPolling: 60 * 1000, // The time interval in ms for polling live now programs

    clockInterval: 5 * 1000, // in ms, delay between each tick
    clockUpdateBufferTime: 60 * 1000, // in ms, amount of time clock tick will be buffered (and thus delay between each clock update)

    // Disable purchase either on ios, android, both or none => possible values are 'IOS', 'ANDROID', 'BOTH' and 'NONE'
    disablePurchasePlatform: 'NONE',

    epgMaxConcurrentRequests: 3,
    epgPrefetchBatchSize: 20, // The number of prefetched channels in the EPG (current day only)

    brandId: null,

    // Currently used only for bookmark related programs
    metaDataMaxConcurrentRequests: 3, // Maximum number of parallel/concurrent request allowed
    metaDataProgramsPerRequest: 15, // Maximum number of programs retrieved in one request

    // in ms, delay waited before checking connection when player start buffer process
    // (used for iOS only)
    checkConnectionOnBufferDelay: 5000,

    // in ms, delay waited before considering that buffer is stalled
    // (used for iOS only)
    bufferStalledDelay: 3000,

    // --- Potentially overriden by clientConfig ---

    // Boolean, true = username is an email (regexp check + translation associated)
    usernameIsAnEmail: false,

    // Indicates on which programs (TVODs and SVODs) we display the Purchase icon
    highlightPurchaseIcon: 'ON_ENTITLED', // possible values: 'ON_ENTITLED', 'ON_NOT_ENTITLED', 'BOTH', '' (if the string is empty, none icon will be displayed)

    // Icon to display on entitled programs (TVODs and SVODs)
    purchaseIconOnEntitled: 'tickPlainPiece', // if the string is empty, none icon will be displayed

    // Icon to display on NOT entitled programs (TVODs and SVODs)
    purchaseIconOnNotEntitled: '', // if the string is empty, none icon will be displayed

    // Icon to display on programs (TVODs and SVODs) that can NOT be watched on iOS, android or both (when disablePurchasePlatform !== 'NONE')
    disablePurchaseIcon: '', // if the string is empty, the purchaseIconOnNotEntitled will be displayed

    // In milliseconds, delay before toast is automatically hidden
    toasterHideDelay: 3000,

    // In seconds, delay between setBookmark during playback
    bookmarkFrequency: 120,

    // Ratio between 0 and 1, theresold used to determine that a content has been fully viewed
    finishedThreshold: 0.95, // 95%

    // request timeout values in ms for each api service
    timeout: {
        auth: 30000,
        configuration: 60000,
        emi: 60000,
        platform: 60000,
        proxy: 60000,
    },

    proxy: {
        device: getBrowserName(voltApi) === 'safari' ? 6 : 1,
    }, // Target devices types are reported on maculosa doc (cf. https://docs.ifeelsmart.net/maculosa-api/api/data-models/common.html#target-devices-type)

    // Mapping to device identifier to use when dealing with EMI configuration
    // Dunno if these id could vary depending on client instance, that's why they are in config and not in constants
    emiDevices: {
        common: 2,
        smartTv: 3,
        pc: 1,
    },

    // The lag (in ms) observed when a recording transitions from "ongoing" to "completed"
    recordingCompletionLag: 0,

    // in seconds
    backToLiveThreshold: 10,

    // in seconds
    forwardAndRewindStep: 10,

    chromecast: {
        enabled: false,
        chromecastProxyUrl: '',
    },
    userAgents: {},

    // prefix to create the PC Level Icon name from the pcValue
    pcLevelIconPrefix: 'parentalRating-',

    // Type of authentication (examples: oauth, basicAuth, ...)
    authentication: {
        // basic access authentication (login / password)
        type: 'basicAuth',
    },

    // playback replated config
    playback: {
        // max number of retries if the playback failed the first time
        MAX_PLAYBACK_RETRIES: 5,
    },

    refreshableRequests: {
        emiRequests: {
            // Coach Live Now Row from EMI (short refresh)
            GET_LIVE_NOW_ALL: {
                validity: requestValidity.INSTANT,
            },
            GET_LIVE_NOW_FAVORITES: {
                validity: requestValidity.INSTANT,
            },
            GET_LIVE_TONIGHT_ALL: {
                validity: requestValidity.DEFAULT,
            },
            GET_LIVE_TONIGHT_FAVORITES: {
                validity: requestValidity.DEFAULT,
            },
            GET_LIVE_TODAY: {
                validity: requestValidity.DEFAULT,
            },
            GET_CATCHUP_BY_PROGRAM: {
                validity: requestValidity.DEFAULT,
            },
            GET_CATCHUP_BY_CHANNEL: {
                validity: requestValidity.DEFAULT,
            },
            GET_CATCHUP_CONTINUE_WATCHING: {
                validity: requestValidity.DEFAULT,
            },
            GET_ALL_VODS: {
                validity: requestValidity.HOUR_1,
            },
            GET_ALL_VODS_MOVIES: {
                validity: requestValidity.HOUR_1,
            },
            GET_ALL_VODS_SERIES: {
                validity: requestValidity.HOUR_1,
            },
            GET_VODS_OF_CATEGORY: {
                validity: requestValidity.HOUR_1,
            },
            GET_VODS_FROM_NODE_PLATFORM_ID: {
                validity: requestValidity.HOUR_1,
            },
            GET_VODS_EPISODES_OF_SEASON: {
                validity: requestValidity.HOUR_1,
            },
            GET_VODS_SEASONS_OF_SERIES: {
                validity: requestValidity.HOUR_1,
            },
            GET_VODS_EPISODES_BY_SERIE: {
                validity: requestValidity.HOUR_1,
            },
            GET_PACKAGES_ALL: {
                validity: requestValidity.DEFAULT,
            },
            GET_PACKAGES_UNSUBSCRIBED: {
                validity: requestValidity.DEFAULT,
            },
            GET_PACKAGES_SUBSCRIBED: {
                validity: requestValidity.DEFAULT,
            },
            GET_PURCHASED_TVODS_MOVIES: {
                validity: requestValidity.HOUR_1,
            },
            GET_PURCHASED_TVODS_SERIES: {
                validity: requestValidity.HOUR_1,
            },
            GET_FAVORITES_ALL: {
                validity: requestValidity.HOUR_1,
            },
            GET_FAVORITES_SERIES: {
                validity: requestValidity.HOUR_1,
            },
            GET_FAVORITES_MOVIES: {
                validity: requestValidity.HOUR_1,
            },
            CONTINUE_WATCHING_VOD_ALL: {
                validity: requestValidity.MIN_40,
            },
            GET_CONTINUE_WATCHING_VOD_AND_CATCHUP: {
                validity: requestValidity.MIN_40,
            },
            CONTINUE_WATCHING_VOD_MOVIES: {
                validity: requestValidity.MIN_40,
            },
            CONTINUE_WATCHING_VOD_TVSHOWS: {
                validity: requestValidity.MIN_40,
            },
            GET_ALL_RECORDINGS_BY_NAME: {
                validity: requestValidity.HOUR_1,
            },
            GET_AVAILABLE_RECORDINGS_BY_DATE: {
                validity: requestValidity.HOUR_1,
            },
            GET_AVAILABLE_EPISODE_RECORDINGS_BY_DATE: {
                validity: requestValidity.HOUR_1,
            },
            GET_SCHEDULED_EPISODE_RECORDINGS_BY_DATE: {
                validity: requestValidity.HOUR_1,
            },
            GET_ALL_SERIES_RECORDINGS_BY_NAME: {
                validity: requestValidity.HOUR_1,
            },
            GET_AVAILABLE_SERIES_RECORDINGS_BY_NAME: {
                validity: requestValidity.HOUR_1,
            },
            GET_FAVORITE_RECORDINGS: {
                validity: requestValidity.HOUR_1,
            },
            GET_FAVORITE_AVAILABLE_RECORDINGS: {
                validity: requestValidity.HOUR_1,
            },
            GET_FAVORITE_SCHEDULED_RECORDINGS: {
                validity: requestValidity.HOUR_1,
            },
            GET_FAVORITE_EPISODE_RECORDINGS: {
                validity: requestValidity.HOUR_1,
            },
            GET_CONTINUE_WATCHING_RECORDINGS: {
                validity: requestValidity.HOUR_1,
            },
            GET_CONTINUE_WATCHING_EPISODE_RECORDINGS: {
                validity: requestValidity.HOUR_1,
            },
            GET_YOUTUBE_PLAYLIST: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_FILM_AND_ANIMATION: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_AUTO_AND_VEHICULES: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_MUSIC: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_PETS_AND_ANIMALS: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_SPORTS: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_SHORT_MOVIES: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_TRAVEL_AND_EVENTS: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_GAMING: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_VIDEO_BLOGGING: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_PEOPLE_AND_BLOGS: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_COMEDY: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_ENTERTAINMENT: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_NEWS_AND_POLITICS: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_HOW_TO_AND_STYLE: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_EDUCATION: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_SCIENCE_AND_TECHNOLOGY: {
                validity: requestValidity.YOUTUBE,
            },
            GET_YOUTUBE_CATEGORY_NON_PROFITS_AND_ACTIVISM: {
                validity: requestValidity.YOUTUBE,
            },
            ANDROID_CHANNEL_APPS_GET_CONTENT_WATCH_NEXT: {
                validity: requestValidity.DEFAULT,
            },
            GENERATE_ANDROID_CHANNEL_APPS_REQUESTS: {
                validity: requestValidity.DEFAULT,
            },
            GENERATE_ANDROID_CHANNEL_APPS_REQUESTS_ALIAS: {
                validity: requestValidity.DEFAULT,
            },
            GET_ALL_APPS_BY_NAME: {
                validity: requestValidity.DEFAULT,
            },
            GET_ALL_GAMES_BY_NAME: {
                validity: requestValidity.DEFAULT,
            },
            GET_ALL_APPS_BY_MOST_RECENT: {
                validity: requestValidity.DEFAULT,
            },
            GET_ALL_GAMES_BY_MOST_RECENT: {
                validity: requestValidity.DEFAULT,
            },
            GET_ALL_APPS_AND_GAMES_BY_NAME: {
                validity: requestValidity.DEFAULT,
            },
            GET_ALL_APPS_AND_GAMES_BY_MOST_RECENT: {
                validity: requestValidity.DEFAULT,
            },
            GET_UPCOMING_GAMES: {
                validity: requestValidity.INSTANT,
            },
            GET_TEAM_SQUAD: {
                validity: requestValidity.HOUR_1,
            },
            GET_ALL_RANKING: {
                validity: requestValidity.HOUR_1,
            },
            GET_LEAGUE_RANKING: {
                validity: requestValidity.HOUR_1,
            },
            GET_PLAYER_RANKING: {
                validity: requestValidity.HOUR_1,
            },
        },
        customRequests: {
            GET_PURCHASED_TVODS: {
                validity: requestValidity.HOUR_1,
            },
            GET_VOD_NODE_CONTENT: {
                validity: requestValidity.HOUR_1,
            },
            GET_CONTENTS_FROM_IDS: {
                validity: requestValidity.HOUR_1,
            },
            GET_AVAILABLE_RECORDINGS_BY_NAME: {
                validity: requestValidity.HOUR_1,
            },
            GET_SCHEDULED_SERIES_RECORDINGS_BY_NAME: {
                validity: requestValidity.HOUR_1,
            },
            GET_SCHEDULED_STANDALONE_EVENT_RECORDINGS_BY_DATE: {
                validity: requestValidity.HOUR_1,
            },
            GENERATE_AVAILABLE_RECORDINGS_BY_MONTH_REQUESTS: {
                validity: requestValidity.HOUR_1,
            },
            ANDROID_CHANNEL_APPS_GET_PREVIEW_PROGRAM_BY_CHANNEL_ID: {
                validity: requestValidity.HOUR_1,
            },
        },
    },

    // For requests that are considered "refreshable", this value specifies the amount of time
    // (after the last successful execution) during which the data retrieved by the request is
    // considered "valid".
    // In milliseconds.
    requestValidity: 15 * 60 * 1000, // 15 minutes (?)

    activeUniverses: {
        replay: true,
        search: true,
        subscriptions: true,
        settings: {
            parentalControl: true,
        },
    },

    enableMaculosaSearch: false,
    searchContentTypes: ['VOD', 'EPG_LIVE', 'EPG_PAST'],

    // NONE = no restriction on Tvods
    // ALL = retrieved TVODs are only the one entitled
    // PARTIAL = same as ALL except for "continue watching" and "my purchases" requests: TVOds are not filtered
    entitledTvodsRestriction: 'NONE', // 'NONE' 'ALL' 'PARTIAL'

    // By default, user Pc Level of -1 allows all content
    defaultUserPcLevel: -1,

    // The number of elements that can be fetched at a time, currently used to retrieve channels
    maximumPageSize: 250,

    // Page size for backend request to get subscriptions. Requests are recursive for Seachange, but there is only one request for Kaltura at the moment. THIS IS A TEMPORARY WORKAROUND, while Kaltura is improving its Api for effective pagination.
    noPaginationPageSize: 200,
    // ----------------------------------------------

    hasPortalScreen: true,

    sandbox: false,

    isMouseEnabled: false,

    // Enables items amount in Row/Header component
    displayContentAmount: true,

    redirectToLoginScreenOnSsoError: true,

    bootUniverse: null,

    enableImageOptimization: false,
    imageSizes: {
        landscape: {
            w: 320,
        },
        portrait: {
            h: 250,
        },
    },
    // Temporary structure to UI control from store
    storeNavigation: {
        disableDrawer: true,
        disableScreenNavigation: true,
        disableBackNavigation: true,
        disablePlayerControl: true,
        disablePlayerError: false,
        disablePlayerNavigation: true,
        disableErrorScreenOnMaxDevicesReached: true,
        // Part to BE tested LATER for the WEB as merged now, we cannot take any risk
        // Two next lines has been put back to false because to breaks playback at boot.
        // We still need to avoid fetching content when starting playback as it slow down zapping...
        // disableAutomaticFetchNextProgram: true,
        // disableUpdateProgramOnPlaybackStart: false,
        // disableFetchContentBeforePlayback: true,
        // fastLiveChannelZapping: true,
    },
}

const _config: {[key: string]: any} = merge(config, baseConfig, client.config)

export default _config
